import storageAvailable from '../../helpers/storage_available';
import trackEvent from '../../helpers/gtm/eventPush';
import '../../../sass/components/list/narrow_by_filters.scss';

const searchForm = document.getElementById('filterForm');
const filterForm = document.getElementById('NarrowByFilters');
const narrowBySearch = document.getElementById('narrow_by_search_options');

//
// Define Prototypes
//

// Method to get inputs (text, number etc)
Element.prototype.getInputs = function() {
  return this.querySelectorAll('input:not([type="radio"])');
};

// Method to get required inputs
Element.prototype.getRequiredInputs = function() {
  const inputs = Array.from(this.getInputs());
  return inputs.filter(
    (input) => input.value === '' && input.matches(':required'),
  );
};

// Method to get valid inputs
Element.prototype.getValidInputs = function() {
  const inputs = Array.from(this.getInputs());
  return inputs.filter(
    (input) => input.value !== '' && input.matches(':valid'),
  );
};

// Method to get invalid inputs
Element.prototype.getInvalidInputs = function() {
  const inputs = Array.from(this.getInputs());
  return inputs.filter(
    (input) => input.value !== '' && input.matches(':invalid'),
  );
};

if (narrowBySearch) {
  const fieldsets = narrowBySearch.querySelectorAll('fieldset');
  let currentFieldset = null;

  //
  // Handle the variable parameter inputs.
  //

  // Function to get invalid input range
  const getInvalidRange = () => {
    if (currentFieldset) {
      const fieldset = currentFieldset;
      const inputs = Array.from(fieldset.getInputs());
      const isRange = !!fieldset.dataset.rangeInputs;
      // Return null if not a range
      if (!isRange || inputs.length < 2) {
        return null;
      }
      // Return max if min is less than max
      const min = parseInt(inputs[0].value, 10);
      const max = parseInt(inputs[1].value, 10);
      if (max < min) {
        return {
          input: inputs[inputs.length - 1],
          message: 'Max cannot be lower than min',
        };
      }
      return null;
    }
    return null;
  };

  // Function to get invalid date
  const getInvalidDate = () => {
    if (!currentFieldset) {
      return null;
    }

    const fieldset = currentFieldset;
    const inputs = Array.from(fieldset.getInputs());
    const isDate = !!fieldset.dataset.dateInputs;

    // Return null if not 3 x date inputs
    if (!isDate || inputs.length < 3) {
      return null;
    }

    const day = inputs[0].value;
    const month = inputs[1].value;
    const year = inputs[2].value;
    const today = new Date();
    const inputDate = new Date(`${year}-${month}-${day}`);
    const twoYearsFuture = new Date(today).setFullYear(today.getFullYear() + 2);

    // Return null if incomplete date
    if (inputDate.toString() === 'Invalid Date') {
      return null;
    }

    // Function to return the index for the invalid part of the date
    // Day: 0, Month: 1, Year: 2
    const getIncorrectDateInput = (date, compare = 'before') => {
      const compareFunction =
        compare === 'after' ? (a, b) => a > b : (a, b) => a < b;

      if (compareFunction(date.getFullYear(), today.getFullYear())) {
        return 2;
      }

      if (date.getFullYear() === today.getFullYear()) {
        if (compareFunction(date.getMonth(), today.getMonth())) {
          return 1;
        }
        if (date.getMonth() === today.getMonth()) {
          if (compareFunction(date.getDate(), today.getDate())) {
            return 0;
          }
        }
      }
      return null;
    };

    // Return input if inputDate is not future
    if (inputDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
      const inputIndex = getIncorrectDateInput(inputDate, 'before');
      return {
        input: inputs[inputIndex],
        message: 'Date cannot be in a the past.',
      };
    }

    // Return input if inputDate is more than 2 years in future
    if (inputDate > twoYearsFuture) {
      const inputIndex = getIncorrectDateInput(inputDate, 'after');
      return {
        input: inputs[inputIndex],
        message: 'Date cannot be more than two years in the future',
      };
    }

    return null;
  };

  // Function to add the 'required' attribute
  const addRequiredAttribute = () => {
    if (currentFieldset) {
      const inputs = currentFieldset.getInputs();
      Array.from(inputs).forEach((input) =>
        input.setAttribute('required', true),
      );
    }
  };

  // Function to remove 'required' attribute
  const removeRequiredAttribute = () => {
    if (currentFieldset) {
      const inputs = currentFieldset.getInputs();
      Array.from(inputs).forEach((input) => input.removeAttribute('required'));
    }
  };

  // Function to remove the 'required' attribute from inputs in other fieldsets
  const removeRequiredAttributeFromOthers = () => {
    Array.from(fieldsets).forEach((fieldset) => {
      if (fieldset !== currentFieldset) {
        const inputs = fieldset.getInputs();
        Array.from(inputs).forEach((input) =>
          input.removeAttribute('required'),
        );
      }
    });
  };

  // Function to empty inputs in other fieldsets
  const emptyInputsInOtherFieldsets = () => {
    Array.from(fieldsets).forEach((fieldset) => {
      if (fieldset !== currentFieldset) {
        const inputs = fieldset.getInputs();
        Array.from(inputs).forEach((input) => (input.value = ''));
      }
    });
  };

  // Function to apply required input rules:
  // Handles special cases like date inputs
  const applyInputRequirements = () => {
    if (currentFieldset) {
      const fieldset = currentFieldset;
      const validInputs = fieldset.getValidInputs();
      const isDateInputs = !!fieldset.dataset.dateInputs;
      const hasValidInput = validInputs.length > 0;

      // When date all are required
      if (isDateInputs) {
        addRequiredAttribute();
        return;
      }

      // Otherwise one valid input in range is required
      if (hasValidInput) {
        removeRequiredAttribute();
      } else {
        addRequiredAttribute();
      }
    }
  };

  // Function to apply validation
  const applyValidation = (input, message = null) => {
    const validityErrors = [
      'patternMismatch',
      'rangeOverflow',
      'rangeUnderflow',
    ];

    // Check for general input validity errors
    if (validityErrors.some((prop) => input.validity[prop])) {
      input.reportValidity();
    }

    // Use custom pattern validation messages from title attribute
    const customValidity = input.getAttribute('title');
    if (customValidity && input.value === '') {
      input.setCustomValidity(customValidity);
      input.reportValidity();
    }

    if (message) {
      input.setCustomValidity(message);
      input.reportValidity();
    }
  };

  // Function to reset validation
  const resetValidation = () => {
    if (currentFieldset) {
      const inputs = currentFieldset.getInputs();
      Array.from(inputs).forEach((item) => item.setCustomValidity(''));
    }
  };

  // Function used to select the input we want to focus:
  // If we find errors like patternMismatch, return that input.
  // If fieldset is set to focusLastInput, return last input.
  // Otherwise return the first input in the fieldset.
  const getFocusInput = (inputs) => {
    const inputErrors = inputs.filter(
      (input) =>
        input.validity.patternMismatch ||
        input.validity.rangeOverflow ||
        input.validity.rangeUnderflow,
    );
    if (inputErrors.length > 0) {
      return inputErrors[0];
    }
    const focusLastInput = currentFieldset?.dataset.focusLastInput;
    const index = focusLastInput ? inputs.length - 1 : 0;
    return inputs[index];
  };

  // Function to apply focus to the given input
  const applyFocus = (input) => {
    if (input && !input.validity.valid) {
      input.focus();
    }
  };

  // Function to process form, apply validation and track
  const processForm = () => {
    // Check for invalid inputs
    const invalidInputs = currentFieldset.getInvalidInputs();
    if (invalidInputs.length > 0) {
      const invalid = getFocusInput(invalidInputs);
      applyFocus(invalid);
      applyValidation(invalid);
      return;
    }

    // Check for empty required inputs
    const requiredInputs = currentFieldset.getRequiredInputs();
    if (requiredInputs.length > 0) {
      const required = getFocusInput(requiredInputs);
      applyFocus(required);
      applyValidation(required);
      return;
    }

    // Check for range input validity
    const invalidRange = getInvalidRange();
    if (invalidRange) {
      const { input, message } = invalidRange;
      applyValidation(input, message);
      return;
    }

    // Check for date input validity
    const invalidDate = getInvalidDate();
    if (invalidDate) {
      const { input, message } = invalidDate;
      applyValidation(input, message);
      return;
    }

    // Apply date input format - leading zero on day/month
    const isDate = !!currentFieldset.dataset.dateInputs;
    if (isDate) {
      const dateInputs = currentFieldset.querySelectorAll('input');
      Array.from(dateInputs).forEach((input) => {
        const { value } = input;
        const { dateType } = input.dataset;
        if (dateType === 'day' || dateType === 'month') {
          input.value = value.length === 1 ? `0${value}` : value;
        }
      });
    }

    // Store name of filter_applied
    if (currentFieldset && storageAvailable('localStorage')) {
      const name = currentFieldset.dataset.filterName || '';
      localStorage.setItem('filter_applied', name);
    }

    // Otherwise submit the form
    filterForm.submit();
    currentFieldset = null;
  };

  // Add event listeners for focus, input and keypress
  Array.from(fieldsets).forEach((fieldset) => {
    const inputs = fieldset.getInputs();

    Array.from(inputs).forEach((input) => {
      input.addEventListener('focus', () => {
        currentFieldset = fieldset;
        removeRequiredAttributeFromOthers();
        applyInputRequirements();
      });

      input.addEventListener('input', () => {
        currentFieldset = fieldset;
        emptyInputsInOtherFieldsets();
        resetValidation();
        applyInputRequirements();
      });

      input.addEventListener('keypress', (event) => {
        if (event.key === 'Enter') {
          if (currentFieldset) {
            resetValidation();
            processForm();
          }
        }
      });
    });
  });

  // Add event listeners for button click
  Array.from(fieldsets).forEach((fieldset) => {
    const buttons = fieldset.querySelectorAll('button');
    Array.from(buttons).forEach((button) => {
      button.addEventListener('click', (event) => {
        currentFieldset = fieldset;
        emptyInputsInOtherFieldsets();
        removeRequiredAttributeFromOthers();
        resetValidation();
        applyInputRequirements();
        processForm();
      });
    });
  });

  //
  // Handle behavior for fixed paramater range inputs i.e selects
  //

  // Disable select options
  const disableSelectOptions = (
    select,
    params = { greaterThan: null, lessThan: null },
  ) => {
    // Enable all options in target select
    enableSelectOptions(select);

    // Get all options in target select
    let options = select.querySelectorAll('option');

    // Filter options if greaterThan is given
    if (params.greaterThan) {
      options = Array.from(options).filter(
        (option) => option.value >= params.greaterThan,
      );
    }

    // Filter options if lessThan is given
    if (params.lessThan) {
      options = Array.from(options).filter(
        (option) => option.value <= params.lessThan,
      );
    }

    // Iterate on options and set disabled
    Array.from(options).forEach((option) => {
      if (option.value !== '') {
        option.disabled = true;
      }
    });
  };

  // Enable select options
  const enableSelectOptions = (select) => {
    Array.from(select.querySelectorAll('option')).forEach((option) => {
      option.disabled = false;
    });
  };

  // Find all the fixed range selects
  Array.from(fieldsets)
    .filter((item) => item.dataset.rangeSelects)
    .forEach((fieldset) => {
      const selects = fieldset.querySelectorAll('select');

      // Only apply when there 2 selects
      if (selects.length !== 2) {
        return;
      }

      const min = Array.from(selects).find((el) => el.dataset.type === 'min');
      const max = Array.from(selects).find((el) => el.dataset.type === 'max');

      // When a select changes we need to disable some
      // of the unreachable options in the other select.
      Array.from(fieldsets).forEach((select) => {
        select.addEventListener('change', (event) => {
          const { value } = event.target;
          const { type } = event.target.dataset;
          const numValue = parseInt(value, 10);
          const otherSelect = type === 'min' ? max : min;
          const filterParams = {};

          // When select type is min
          if (numValue && type === 'min') {
            filterParams.lessThan = numValue;
          }

          // When select type is max
          if (numValue && type === 'max') {
            filterParams.greaterThan = numValue;
          }

          // Disable with filtering options
          disableSelectOptions(otherSelect, filterParams);

          // When value is empty (any) enable all options
          if (value === '') {
            enableSelectOptions(otherSelect);
          }
        });
      });
    });

  //
  // Handle changes in types checkboxes in search panel form
  //

  if (searchForm) {
    const searchTypes = searchForm.querySelectorAll('.narrow_by__type input');
    Array.from(searchTypes).forEach((checkbox) => {
      checkbox.addEventListener('change', (ev) => {
        // Update corresponding hidden value in the filter form
        const { checked, name } = ev.target;
        const filterTypeInput = filterForm.querySelector(`input[name=${name}]`);
        if (filterTypeInput) {
          filterTypeInput.value = checked ? 'Y' : '';
        }
      });
    });
  }

  //
  // Decide whether to show the clear filters link / no filters messaging.
  // Also fire tracking event when filters are shown.
  //

  const filtersSelected = document.querySelectorAll('.narrow_by__selected a');
  const filtersRendered = document.querySelectorAll('.narrow_by__criterion');
  const noFilters = document.querySelector('.narrow_by__no_filters');
  const clearFiltersLink = document.querySelector('.narrow_by__reset');

  if (filtersRendered.length < 1) {
    clearFiltersLink.style.display = 'none';
    noFilters.style.display = 'block';
    if (filtersSelected < 0) {
      noFilters.querySelector('p').innerText =
        'There are no filters available for this search';
    }
  }

  if (filtersRendered.length > 0) {
    trackEvent({
      category: 'narrow',
      action: 'filters_shown',
      label: '',
    });
  }

  //
  // Tracking events for reset links
  //

  const resetLinks = narrowBySearch.querySelectorAll('.narrow_by__reset_link');

  if (resetLinks) {
    Array.from(resetLinks).forEach((link) => {
      const name = link ? link.textContent : '';
      link.addEventListener('click', () => {
        if (storageAvailable('localStorage')) {
          localStorage.setItem('filter_applied', 'filtered_search_reset');
        }
      });
    });
  }

  //
  // Tracking events for fixed paramater links
  //

  const filterLinks = narrowBySearch.querySelectorAll('.narrow_by__criterion');

  if (filterLinks) {
    Array.from(filterLinks).forEach((item) => {
      const link = item.querySelector('a');
      if (link) {
        const name = link ? link.textContent : '';
        link.addEventListener('click', () => {
          if (storageAvailable('localStorage')) {
            localStorage.setItem('filter_applied', name);
          }
        });
      }
    });
  }
}
